import { cn } from '#app/utils/misc.js'

export function Box({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <main
      className={cn(
        'rounded-lg bg-user-nav-light-blue p-8 shadow-sm',
        className,
      )}
    >
      {children}
    </main>
  )
}
